import './App.css';
import Nav from './components/Nav';

import Body from './components/Body';

function App() {
  return (
    <div className="App">
     <Nav />
     <Body />
    </div>
  );
}

export default App;
